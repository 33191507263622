import * as React from "react";
import { useMediaQuery } from 'react-responsive';

function ProblemSegment() {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div className="bg-black-white-gradient pt-40">
      <div className={`justify-center items-stretch flex w-full flex-col px-24 max-md:max-w-full max-md:px-5 ${isMobile ? "" : "py-10"}`}>
        
          <div className="text-zinc-300 text-center text-6xl font-bold self-center max-md:max-w-full max-md:text-5xl max-md:mt-10 max-md:flex max-md:flex-col">
            <span className="text-violet-700">Struggle</span>{" "}
            <span className="text-grey">to get a study place?</span>
          </div>                     
        
        <div className="justify-center text-zinc-300 text-center text-2xl font-light self-center max-w-[609px] mt-9 max-md:max-w-full max-md:text-xl">
          Booking a study place is often more stressful than the actual
          exams!
        </div>

        <div className="mt-40 max-md:max-w-full max-md:mt-10 overflow-visible">
          <div className="gap-24 flex max-md:flex-col max-md:items-center max-md:gap-0">

            <div className="flex flex-col items-center justify-center w-[45%] max-md:w-[70%] max-md:mt-0 rounded-[30px] transform hover:scale-105 transition-all duration-200 hover:bg-opacity-70">
              <div
                className="bg-cover bg-center flex grow flex-col items-stretch w-full px-8 py-11 rounded-[30px] max-md:max-w-full max-md:mt-10 max-md:px-5"
                style={{ backgroundImage: `url("https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d6320e5c2ecfd29f0e9efee9f205839faa4e78089231a25f48e4a02b51bf61d7?apiKey=0728f582a8894e8ea254adb5ab83d638&")` }}
              >                                
                  <div className="relative text-black text-center text-5xl font-extrabold whitespace-nowrap max-md:text-4xl">
                    <span className="text-white">Additional</span>{" "}
                    {isMobile ? (
                      <br />
                    ): null}
                    <span className="text-red-600">STRESS</span>
                  </div>                
                
                <div className="text-center text-stone-200 text-2xl text-shadow font-bold self-stretch mt-16 mb-5 max-md:mt-10 max-md:text-xs hover:text-white">
                  <span>
                    Struggling to reserve a seat at the local library 
                    is an unnecessary factor of stress!
                  </span>
                  <br />
                  <br />
                  <span>
                    RESERVE allows you to focus 100% on your exams, without additional stress!
                  </span>                                    
                </div>
              </div>
            </div>

            <div className="flex flex-col items-stretch w-[45%] max-md:w-[70%] max-md:mt-0 rounded-[30px] transform hover:scale-105 transition-all duration-200 hover:bg-opacity-70">
              <div
                className="bg-cover bg-center flex grow flex-col items-stretch w-full px-8 py-11 rounded-[30px] max-md:max-w-full max-md:mt-10 max-md:px-5"
                style={{ backgroundImage: `url("https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/18e95e2d0379228f5ed6d7de10276b703b1459fae326e008c6e273780d7b59b2?apiKey=0728f582a8894e8ea254adb5ab83d638&")` }}
              >
                <div className="relative text-black text-center text-5xl font-extrabold whitespace-nowrap max-md:text-4xl">
                  <span className="text-white">Daily</span>{" "}
                    {isMobile ? (
                      <br />
                    ): null}
                  <span className="text-red-600">STRUGGLE</span>
                </div>
                <div className="text-center text-stone-200 text-2xl font-bold self-stretch mt-16 mb-5 max-md:mt-10 max-md:text-xs hover:text-white">
                  A daily headache comes from trying to reserve your study spot at the local library,
                  esspecially during exam weeks this can be painful.                    
                  <br />  
                  <br />              
                  RESERVE gets rid of this pain for you!
                </div>
              </div>
            </div>

            <div className="flex flex-col items-stretch w-[45%] max-md:w-[70%] max-md:mt-0 rounded-[30px] transform hover:scale-105 transition-all duration-200 hover:bg-opacity-70">
              <div
                className="bg-cover bg-center flex grow flex-col items-stretch w-full px-8 py-11 rounded-[30px] max-md:max-w-full max-md:mt-10 max-md:px-5"
                style={{ backgroundImage: `url("https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4b7d949569c30f2193276a447888504d02cfe9602bbedc16209ddcac6925b188?apiKey=0728f582a8894e8ea254adb5ab83d638&")` }}
              >
                <div className="relative text-black text-center text-5xl font-extrabold whitespace-nowrap max-md:text-4xl">
                  <span className="text-white">No</span>{" "}
                    {isMobile ? (
                      <br />
                    ): null}
                  <span className="text-red-600">PLANING</span>
                </div>
                <div className="text-center text-stone-200 text-2xl font-bold self-stretch mt-16 mb-5 max-md:mt-10 max-md:text-xs hover:text-white">
                  Limited planning of your study spots adds extra daily things to keep track of, 
                  long term planning is crucial for good results
                  <br />
                  <br />
                  RESERVE helps you to plan ahead!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProblemSegment;