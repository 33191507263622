import React from "react";
import { useMediaQuery } from "react-responsive";

function ProblemSegment(){
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

    return (
    <div className="bg-white-black-gradient">
      <div className={`justify-center items-center flex w-full flex-col px-16 max-md:max-w-full max-md:px-5 ${isMobile ? "py-3" : "py-12"}`}>
        <div className="flex w-full max-w-[1402px] flex-col items-stretch mt-16 mb-60 max-md:max-w-full max-md:my-10">
          <div className="text-black text-center text-6xl font-extrabold max-w-[776px] self-center max-md:max-w-full max-md:text-5xl">
            <span className="text-violet-700">Always</span> secure your <br />
            study <span className="text-violet-700">spot</span>
          </div>
          <div className="mt-80 max-md:max-w-full max-md:mt-10">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">

              <div className="flex grow flex-col items-center max-md:mt-10 justify-center">         
              <div className=                
                "aspect-[1/1]  bg-stone-900 text-stone-100 flex items-center justify-center text-5xl font-extrabold w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full max-md:text-4xl"              
              >
                1 
              </div>
              <div className=
                "text-center text-3xl font-light leading-10 self-stretch whitespace-nowrap mt-12 max-md:text-4xl max-md:mt-10"
              >
                <span className="font-bold text-white">
                  Create {" "}
                </span>              
                <br/>
                <span className="font-bold text-white">
                  account
                </span>{" "}
              </div>
            </div>

              <div className="flex grow flex-col items-center max-md:mt-10 justify-center">
                <div className=
                  "aspect-[1/1] bg-stone-700 text-stone-100 flex items-center justify-center text-5xl font-extrabold w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full max-md:text-4xl"              
                >
                  2
                </div>
                <div className=                  
                  "text-center text-3xl font-light leading-10 self-stretch whitespace-nowrap mt-12 max-md:text-4xl max-md:mt-10"
                >
                  <span className="text-white font-bold">Pick <br /> University</span>
                </div>
              </div>

              <div className="flex grow flex-col items-center max-md:mt-10 justify-center">
                <div className=
                  "aspect-[1/1]  bg-stone-500 text-stone-300 flex items-center justify-center text-5xl font-extrabold w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full max-md:text-4xl"              
                >
                  3
                </div>
                <div className=                  
                  "text-center text-3xl font-light leading-10 self-stretch whitespace-nowrap mt-12 max-md:text-4xl max-md:mt-10"
                >
                  <span className="text-white font-bold">Select <br /> seat</span>
                </div>
              </div>

              <div className="flex grow flex-col items-center max-md:mt-10 justify-center">
                <div className=
                  "aspect-[1/1] bg-stone-300 text-stone-700 flex items-center justify-center text-5xl font-extrabold w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full max-md:text-4xl"              
                >
                  4
                </div>
                <div className=                  
                  "text-center text-3xl font-light leading-10 self-stretch whitespace-nowrap mt-12 max-md:text-4xl max-md:mt-10"
                >
                  <span className="font-bold text-white">
                    Choose{" "}
                  </span>
                  <br />
                  <span className="font-bold text-white">
                    date & time
                  </span>{" "}
                </div>
              </div>

              <div className="flex grow flex-col items-center max-md:mt-10 justify-center">
                <div className=                              
                  "aspect-[1/1] bg-stone-100 text-stone-900 flex items-center justify-center text-5xl font-extrabold w-16 h-16 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-full max-md:text-4xl"
                >
                  5
                </div>
                <div className=                  
                  "text-center text-3xl font-light leading-10 self-stretch whitespace-nowrap mt-12 max-md:text-4xl max-md:mt-10"
                >
                <span className="font-bold text-white">
                  Confirm
                </span>{" "}
                <br />
                <span className="font-bold text-white">
                  Event                  
                </span>{" "}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}


export default ProblemSegment;