import * as React from "react";
import { useState } from "react";
import { getDocs, addDoc, collection, query, where, serverTimestamp } from "firebase/firestore"; 
import { db } from "../firebase"
// import { getFunctions, httpsCallable } from 'firebase/functions';
import { useMediaQuery } from 'react-responsive';
import Swal from "sweetalert2"
import tempDashboard from "../assets/dashboard.png"




  // function OpeningPage() {
  //   const [email, setEmail] = useState(''); 
  //   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  
  //   async function joinWhitelist(e) {
  //     e.preventDefault(); 
  
  //     const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
  //     if (!emailRegex.test(email)) {
  //       Swal.fire('Invalid email', 'Please enter a valid email address.', 'error');
  //       return;
  //     }
  
  //     try {
  //       const q = query(collection(db, "whitelist"), where("email", "==", email));
  //       const querySnapshot = await getDocs(q);
  //       if (!querySnapshot.empty) {
  //         Swal.fire('Email already on the whitelist', 'Please use a different email', 'info');
  //         return;
  //       }
  
  //       await addDoc(collection(db, "whitelist"), { email, timestamp: serverTimestamp() });
  //       Swal.fire('Success', 'Email added to the whitelist!', 'success');
  //       setEmail('');
  //     } catch (error) {
  //       console.error("Error adding email to whitelist: ", error);
  //       Swal.fire('Error', 'There was an issue adding your email to the whitelist. Please try again later.', 'error');
  //     }
  //   }





  function OpeningPage() {
    const [email, setEmail] = useState('');
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  
    const timeframe = 60000; 
    const requestLimit = 10;
  
    const getTimestamps = () => {
      const timestamps = localStorage.getItem('requestTimestamps');
      return timestamps ? JSON.parse(timestamps) : [];
    };
  
    const addTimestamp = (timestamp) => {
      const timestamps = getTimestamps();
      timestamps.push(timestamp);
      localStorage.setItem('requestTimestamps', JSON.stringify(timestamps));
    };
  
    const cleanOldTimestamps = () => {
      const now = Date.now();
      let timestamps = getTimestamps();
      timestamps = timestamps.filter(ts => now - ts < timeframe);
      localStorage.setItem('requestTimestamps', JSON.stringify(timestamps));
    };
  
    async function joinWhitelist(e) {
      e.preventDefault();
  
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!emailRegex.test(email)) {
        Swal.fire('Invalid email', 'Please enter a valid email address.', 'error');
        return;
      }
  
      cleanOldTimestamps();
      const timestamps = getTimestamps();
  
      if (timestamps.length >= requestLimit) {
        Swal.fire('Too many requests', 'Please wait before trying again.', 'warning');
        return;
      }
  
      try {
        const q = query(collection(db, "whitelist"), where("email", "==", email));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          Swal.fire('Email already on the whitelist', 'Please use a different email', 'info');
          return;
        }
  
        await addDoc(collection(db, "whitelist"), { email, timestamp: serverTimestamp() });
        Swal.fire('Success', 'Email added to the whitelist!', 'success');
        setEmail('');
        addTimestamp(Date.now());
      } catch (error) {
        console.error("Error adding email to whitelist: ", error);
        Swal.fire('Error', 'There was an issue adding your email to the whitelist. Please try again later.', 'error');
      }
    }

  

// function OpeningPage() {
//   const [email, setEmail] = useState(''); 
//   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
//   const functions = getFunctions(app);
  

//   async function joinWhitelist(e) {
//     e.preventDefault(); 

//     const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;    
//     if (!emailRegex.test(email)) {
//       Swal.fire('Invalid email', 'Please enter a valid email address.', 'error');
//       return;
//     }

//     try {
//       const addToWhitelist = httpsCallable(functions, 'addToWhitelist');
//       await addToWhitelist({ email });
//       Swal.fire('Success', 'Email added to the whitelist!', 'success');
//       setEmail('');
//     } catch (error) {
//       console.error("Error adding email to whitelist: ", error, error.message,);
//       // Swal.fire('Error', 'please try later again', 'error');
//       Swal.fire('Error', error.message || 'Please try again later', 'error');

//     }
//   }

    return (
      <div className={`flex flex-col items-stretch ${isMobile ? " ":" "}`}>
          <div className="bg-white-black-gradient">
            <div className={`justify-center items-center flex w-full flex-col pl-14 pr-10 pt-4 pb-12 max-md:max-w-full max-md:px-5 ${isMobile ? " ":" "}`}>
              
              {!isMobile ? (
              <div className={`self-center text-black text-center font-bold leading-[105px] w-full max-md:max-w-full md:text-8xl max-md:leading-[40px] max-md:mt-28 ${isMobile ? "mt-28 text-6xl max-md:flex max-md:flex-col":"mt-32 text-8xl"}`}>
                <span className="text-violet-700">Secure</span> 
                <span> your{" "} </span>  
                <span className="text-violet-700">study spot</span> 
                <br/>
                <span> with  </span> 
                <span className=" text-violet-700"> ease</span>
              </div>
              ) : null}
              
              {isMobile ? (
              <div className={`self-center text-black text-center font-bold leading-[105px] w-full max-md:max-w-full md:text-6xl max-md:leading-[40px] max-md:mt-28 ${isMobile ? "mt-28 text-6xl max-md:flex max-md:flex-col space-x-2 space-y-4":"mt-32 text-8xl"}`}>
                <div className="max-md:justify-center max-md:items-center">
                  <span className="text-violet-700">Secure</span> 
                </div>
                <div className="max-md:justify-center max-md:items-center">
                  <span>your </span>
                  <span className="text-violet-700">study spot</span> 
                </div>
                <div className="max-md:justify-center max-md:items-center">
                  <span>with </span> 
                  <span className="text-violet-700">ease</span>
                </div>
              </div>
              ): null}
              
              <div className={`flex flex-col items-center justify-center h-full ${isMobile ? " ":" "}`}>
                <div className={`self-center text-center text-xl font-thin max-md:max-w-[70%] md:text-xl max-md:leading[12px] max-md:mt-5 ${isMobile ? "mt-5 text-xs text-stone-200" : "mt-10 text-stone-500"}`}>
                  Your personal helper to study stress free at the library and crush
                  your upcoming exams
                  <span className="block md:inline"> </span>
                  {!isMobile ? (                
                    <br/>                    
                  ): null}
                  <span className={`font-bold text-stone-100 ${isMobile ? " " : ""}`}> JOIN NOW TO BE THE FIRST IN LINE! </span> 
                </div>    

                  <form 
                    className={`flex ${isMobile ? "flex-col mt-10 space-y-2" : "flex-row space-x-5"}`}
                    onSubmit={joinWhitelist}>
                    <input 
                      className={`flex items-stretch self-center justify-center border w-64 border-gray-300 mt-28 px-6 py-3 rounded-[30px] max-md:my-10 max-md:pr-5 ${isMobile ? " " : "mb-48"}`} 
                      type="email"                      
                      placeholder="Enter your email" 
                      required  
                      value={email}
                      onChange={e => setEmail(e.target.value)}/>                         
                    <button className={`text-white text-center text-xl font-bold whitespace-nowrap shadow-lg items-stretch bg-gradient-to-r from-amber-500 to-violet-700 self-center justify-center px-6 py-3 rounded-[30px] max-md:my-10 max-md:pr-5 transform hover:scale-110 transition-all duration-200 ${isMobile ? "mt-5":"mt-28 mb-48"}`}
                      type = "submit">
                      Join Whitelist
                    </button>
                  </form>

                <div className="flex justify-center items-center px-10">
                  <img 
                    className={`rounded-[15px] max-md:mt-20 ${isMobile ? " " : ""}`}
                    src={`${tempDashboard}`} 
                    alt="Placeholder"  />
                </div>                

              </div>
            </div>
          </div> 
      </div>       
    );
}

export default OpeningPage;