import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Transitions from "./components/design/Transitions";
import OpeningSegment from './pages/OpeningSegment';
import ProblemSegment from './pages/ProblemSegment';
import ComparisonSegment from './pages/ComparisonSegment';
import WalkTroughSegment from './pages/WalkthroughSegment';
import Footer from './pages/Footer';
import Navigationbar from './components/navbar/Navbar_lite';
import Locations from "./pages/LocationsSegment";


function App() {
  
return (
  <Router>          
        <Routes>          
          <Route path="/" element={<>
            <div id = "navbar">
              <Navigationbar />
            </div>            
            <div id = "opening">
              <OpeningSegment />
            </div>
              <Transitions type="black" />          
            <div id = "problem">
              <ProblemSegment />
            </div> 
            <Transitions type="white" />  
            <Transitions type="white" />          
            <div id = "walkthrough">  
              <WalkTroughSegment />
            </div>     

            <div id = "locations">
              <Locations /> 
            </div>
              <Transitions type="black" />    

              <Transitions type="white" />          
            <div id = "comparison">  
              <ComparisonSegment />
            </div>

              <Transitions type="black" />          
            <div id = "footer">
              <Footer />
            </div>
            </>
          } />
        </Routes>       
    </Router>
  );
}

export default App;