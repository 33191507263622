import React from 'react';


const Transitions = ({type, children }) => {
    switch (type) { 
        case 'black':
            return (
                <div className="bg-black flex min-h-[100px] w-full flex-col max-md:max-w-full max-sm:max-w-full" >
                {children}
                </div>
            );
        case 'white':
            return( 
                <div className="bg-white flex min-h-[100px] w-full flex-col max-md:max-w-full max-sm:max-w-full" >
                {children}
                </div>
            );
        default:
            return(
                <div className="bg-black flex min-h-[100px] w-full flex-col max-md:max-w-full max-sm:max-w-full" >
                {children}
                </div>
            );
    }
}

export default Transitions;