import React, { useState, useEffect } from 'react';
import '../../App.css';

function NavigationBar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  // const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const toggleMenu = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   const handleResize = () => setWindowWidth(window.innerWidth);    
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 1000) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);



  
  return (
    <div className="navigation-bar">
      <div className={`items-center w-full py-4 ${windowWidth < 760 ? "grid grid-cols-2 px-7" : "grid grid-cols-3 px-14"}`}>
        <div className='flex justify-start'>
          <button className="text-amber-500 text-center text-4xl font-bold">
            <span className="text-violet-700">reserve</span>
            <span className="text-amber-500">.</span>
          </button>
        </div>
        {windowWidth < 1000 ? (
          <div className='flex justify-end'>
            <button onClick={toggleMenu} className="text-amber-500 text-center text-4xl font-bold">
              ☰
            </button>
          </div>
        ) : null}
      
      {isOpen ? (
        <div className="bg-gray-200 fixed top-0 left-0 w-full h-[350px] flex flex-col items-start space-y-4 px-14 py-4">
          <button onClick={toggleMenu} className="text-amber-500 text-center text-4xl font-bold self-end">
            X
          </button>
          <a onClick={toggleMenu} href="#problem" className="text-black text-center text-xl font-bold">
            Struggle
          </a>
          <a onClick={toggleMenu} href="#comparison" className="text-black text-center text-xl font-bold">
            Benefits
          </a>
          <a onClick={toggleMenu} href="#walkthrough" className="text-black text-center text-xl font-bold">
            Guide
          </a>
          <a onClick={toggleMenu} href="#locations" className="text-black text-center text-xl font-bold">
            Locations
          </a>
          <a onClick={toggleMenu} href="#footer" className="text-black text-center text-xl font-bold">
            Contact
          </a>
        </div>
      ) : null}

        <div className={`flex justify-center gap-20 ${windowWidth >= 1000 || isOpen ? '' : 'hidden'}`}>
            <a href="#problem" className="text-black text-center text-xl font-bold">
              Struggle
            </a>
            <a href="#comparison" className="text-black text-center text-xl font-bold">
              Benefits
            </a>
            <a href="#walkthrough" className="text-black text-center text-xl font-bold">
              Guide
            </a>
            <a href="#locations" className="text-black text-center text-xl font-bold">
              Locations
            </a>
            <a href="#footer" className="text-black text-center text-xl font-bold">
              Contact
            </a>
          </div>            
          </div>
    </div>
  );
}

export default NavigationBar;