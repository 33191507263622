import React from "react";
import { useMediaQuery } from "react-responsive";

function ComparisonSegment(){
 const isMobile = useMediaQuery ({ query: "(max-width: 767px)"});

return (
<div className="bg-white-black-gradient">
      <div className={`justify-center items-center z-[1] flex w-full flex-col px-16 max-md:max-w-full max-md:mt-10 max-md:px-5 ${isMobile ? "py-3" : "py-12"}`}>
        <div className="flex w-full max-w-[1235px] flex-col items-center mb-60 max-md:max-w-full">
          <div className="text-neutral-600 text-center text-6xl font-extrabold w-full max-md:text-5xl">
            <span className="text-6xl text-violet-700 max-md:text-5xl"> Benefits 
            <span className="text-black"> of </span> reserve</span>
            <span className="text-7xl text-amber-500">.</span> 
            <br />
            <span className="font-light text-3xl text-neutral-600 max-md:text-xl">
              put’s you ahead
            </span>
          </div>
          <div className="self-center mt-36 max-md:max-w-[90%] max-md:mt-10">
            <div className="gap-20 flex max-md:flex-col max-md:items-stretch ">
              <div 
                className="flex flex-col items-center w-[45%] max-md:w-full max-md:ml-0 hover:scale-105 transitionhover-all duration-200 cursor-pointer"                
              >

                <div className="bg-neutral-600 flex grow flex-col items-center px-12 py-11 rounded-[30px] max-md:max-w-full max-md:mt-10 max-md:px-5">
                  <div className="text-zinc-300 text-center text-5xl font-extrabold whitespace-nowrap max-md:text-4xl">
                    <span className="text-red-600">Without</span>{" "}
                    <span className="text-zinc-300">reserve.</span>
                  </div>
                  <div className="text-zinc-300 text-2xl self-center w-[383px] max-w-full mt-16 mb-10 max-md:my-10">
                    - Everyday struggle 
                    <br />
                    <br />
                    <br />
                    - Additional stress
                    <br />
                    <br />
                    <br />
                    - Study at leftover places
                    <br />
                    <br />
                    <br />
                    - Getting kicked out of your seat
                  </div>
                </div>
              </div>
             
                  
              <div 
                className="flex flex-col items-stretch w-[45%] max-md:w-full max-md:mt-0 hover:scale-105 transition-all duration-200 cursor-pointer"
                //onClick = {handleTryForFreeClick}
                >
                <div className="bg-neutral-200 flex grow flex-col items-stretch w-full px-12 py-11 rounded-[30px] max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <div className="relative text-black text-center text-5xl font-extrabold whitespace-nowrap max-md:text-4xl">
                      <span className="text-green-600">With</span>{" "}
                      <span className="text-violet-700">reserve</span>
                      <span className="text-amber-500">.</span>
                    </div>
                    <div className="relative text-black text-2xl font-medium self-stretch mt-16 mb-5 max-md:mt-10">
                      - Book your places weeks in advance
                      <br />
                      <br />
                      - No additional stress
                      <br />
                      <br />
                      - Study at your favourite spot, all day
                      <br />
                      <br />
                      - Overview of your library bookings
                      <br /> 
                      <br />
                      - Full focus on exams         
                    </div>
                 
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    );
}

export default ComparisonSegment;